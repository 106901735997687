<template>
  <div
    v-if="providerDetail"
    v-show="!$cr.breakpoint.lgAndUp"
    ref="mobileTripFooter"
    class="mobile-trip-footer"
  >
    <v-layout align-center justify-center dense style="margin: 0 22px">
      <v-flex>
        <v-layout dense align-center justify-center style="max-width: 700px">
          <v-flex>
            <h4>${{ providerDetail.totalAmount }}</h4>
            <v-layout dense justify="start" align-center>
              <v-flex>
                <CStarRating
                  style="margin-top: 2px"
                  :rating="providerDetail.company.rating"
                />
              </v-flex>
              <v-flex style="min-width: 50px">
                {{ providerDetail.company.rating }}
              </v-flex>
            </v-layout>
          </v-flex>
          <v-layout
            xs4
            row
            align-center
            class="margin-t-2"
          >
            <div>
              <CRIcon
                class="cursor-default"
                viewBox="0 0 24 24"
                width="35"
                height="35"
              >
                verified_user
              </CRIcon>
            </div>
            <div class="margin-t-n2 margin-l-2">
              <h5 class="booking-protection-header">
                Booking Protection Guarantee
              </h5>
              <InfoModal :modal-detail="bookingProtectionModalDetail">
                <template #activator="{ on, attrs }">
                  <div
                    class="learn-more-text cursor-pointer margin-t-n2 margin-b-n4"
                    v-bind="attrs"
                    v-on="on"
                  >
                    Learn more
                  </div>
                </template>
              </InfoModal>
            </div>
          </v-layout>
          <v-flex xs4 :style="$cr.breakpoint.smAndUp ? 'max-width:350px' : ''">
            <v-btn
              id="provider-button-checkout-mobile"
              block
              small
              class="primary"
              @click="$emit('click')"
            >
              <span style="color: white">Continue to Booking</span>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import InfoModal from '@/components/charterup/InfoModal.vue';
import { bookingProtectionModalDetail } from '@/utils/infoModal';

export default {
  components: {
    InfoModal,
  },
  props: {
    providerDetail: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      bookingProtectionModalDetail: bookingProtectionModalDetail,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@/scss/charterup/design';

.learn-more-text {
  color: $blue !important;
  font-size: 14px;
  font-weight: 600;
  text-decoration: underline;
  text-underline-offset: 3px;
}

.mobile-trip-footer {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  left: 0;
  z-index: 10;
  width: 100vw;
  height: 80px;
  background-color: $white;
  border-top: solid 1px $border-gray;
  transition: top 0.2s ease-in-out;
}

.nav-up {
  bottom: -80px;
}

.booking-protection-header {
  font-size: 15px;
  font-weight: bold;
}
</style>
