<template>
  <div style="margin-top: 6px">
    <ProviderInfoHeader :company="company" />
    <v-divider style="margin-top: 15px" />
    <ProviderInfoVehicleDetails :company="company" />
    <ProviderInfoCompanyInfo :company="company" />
    <ProviderInfoCertifiedPartner v-if="isCertifiedPartner" />
    <ProviderInfoRules :company="company" />
    <ProviderInfoInsurace :company="company" />
    <ProviderInfoReviews :company="company" />
  </div>
</template>

<script>
import ProviderInfoVehicleDetails from '@/components/charterup/ProviderInfoVehicleDetails.vue'
import ProviderInfoHeader from '@/components/charterup/ProviderInfoHeader.vue'
import ProviderInfoRules from '@/components/charterup/ProviderInfoRules.vue'
import ProviderInfoInsurace from '@/components/charterup/ProviderInfoInsurace.vue'
import ProviderInfoReviews from '@/components/charterup/ProviderInfoReviews.vue'
import ProviderInfoCompanyInfo from '@/components/charterup/ProviderInfoCompanyInfo.vue'
import ProviderInfoCertifiedPartner from '@/components/charterup/ProviderInfoCertifiedPartner.vue'

export default {
  components: {
    ProviderInfoVehicleDetails,
    ProviderInfoHeader,
    ProviderInfoRules,
    ProviderInfoInsurace,
    ProviderInfoReviews,
    ProviderInfoCompanyInfo,
    ProviderInfoCertifiedPartner,
  },
  props: {
    providerDetail: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      carouselIndex: 0,
      imageModal: false,
    }
  },
  computed: {
    company() {
      return this.providerDetail?.company
    },
    isCertifiedPartner() {
      return !!this.providerDetail?.company?.preferred
    },
    companyPhotos() {
      return this.providerDetail?.company?.vehiclePhotos
    },
    charterBuses() {
      return this.providerDetail?.company?.vehiclePhotos.filter(
        (vehiclePhoto) => {
          return vehiclePhoto.vehicleTypeDTO.key === 'charter_bus'
        }
      )
    },
    miniBuses() {
      return this.providerDetail?.company?.vehiclePhotos.filter(
        (vehiclePhoto) => {
          return vehiclePhoto.vehicleTypeDTO.key === 'mini_bus'
        }
      )
    },
    busPhotoDictionary() {
      const busPhotoDictionaryHelper = {}
      if (this.charterBuses) {
        busPhotoDictionaryHelper['Charter Bus'] = this.charterBuses
      }
      if (this.miniBuses) {
        busPhotoDictionaryHelper['Mini Bus'] = this.miniBuses
      }
      if (this.sprinters) {
        busPhotoDictionaryHelper.Sprinter = this.sprinters
      }
      return busPhotoDictionaryHelper
    },
  },
  methods: {
    openModal(image) {
      this.imageModal = true
      this.carouselIndex = this.getIndex(image)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/scss/charterup/design';

.image-viewer {
  position: absolute;
  right: 25px;
  bottom: 10px;
  z-index: 1;
}

.modal-image-viewer {
  width: 100%;
  height: 100%;
  background-color: $white;
  border-radius: 0;
}

.close-image-viewer {
  position: absolute;
  top: 10px;
  right: 10px;
}

.modal-toolbar {
  padding-top: 10px;
  margin-right: 10px;
  color: $gray;
}
</style>
