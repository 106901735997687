<template>
  <div>
    <div style="padding-top: 12px; padding-bottom: 5px">
      <p>
        <v-layout row no-gutters>
          <CStarRating style="margin-top: 1px" :rating="company.rating" />
          <span style="font-size: 14px; font-weight: 600">
            {{ company.rating }} {{ `(${company.reviewCount} reviews)` }}
          </span>
          <v-spacer />
        </v-layout>
      </p>
    </div>

    <div v-if="company.companyReviews2 && company.companyReviews2.length > 0">
      <article
        v-for="(review, i) in company.companyReviews2"
        :key="i"
        :style="`border-bottom: 1px solid ${$cr.theme.borderGray}`"
      >
        <h4>
          {{ review.customerName }}
        </h4>
        <CStarRating :rating="review.rating" />
        <p>
          {{ review.review }}
        </p>
      </article>
    </div>
    <div v-else style="margin-top: 12px">No information has been provided.</div>
  </div>
</template>

<script>
export default {
  props: {
    company: {
      type: Object,
      required: true,
    },
  },
}
</script>
